<template>
	<div>
		<!-- height="calc(100vh - 20px)" -->
		<audio id="audioFromExternal" controls src="https://api.telegram.org/file/bot1843850039:AAG8Z8pdLoPHN7_VLAsjPQVaVWoT_fxseZ0/voice/file_4.oga"></audio>
		<vue-advanced-chat
			height="80vh"
			:current-user-id="currentUserId"
			:rooms="JSON.stringify(rooms)"
			:rooms-loaded="roomsLoaded"
			:messages="JSON.stringify(messages)"
			:messages-loaded="messagesLoaded"
			@open-file="openFile($event.detail[0])"
			@send-message="sendMessage($event.detail[0])"
			@fetch-messages="fetchMessages($event.detail[0])"
			@fetch-more-room="fetchMoreRooms($event.detail[0])"
		/>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { register } from 'vue-advanced-chat'
register()

export default {
	data() {
		return {
			currentUserId: "001",
			rooms: [],
			messages: [],
			messagesLoaded: false,
			roomsLoaded: false,
			roomParams: {
				id: '',
				chat_id: '',
			},
			current_page: 0,
			messageWatchInterval: null,

		}
	},

	computed: {
        ...mapGetters({
            userList: "telegramBotMessangerUser/list",
            messageList: "telegramBotMessangerMessage/list",
            columns: "telegramBotMessangerMessage/columns",
            pagination: "telegramBotMessangerMessage/pagination",            
            filter: "telegramBotMessangerMessage/filter",
            sort: "telegramBotMessangerMessage/sort",
            unseenMessages: "telegramBotMessangerMessage/unseenMessages",
            mode: "MODE"
        }),
    },

	watch: {
		"roomParams.chat_id": {
			handler: async function(newVal, oldVal) {
				
			},
			deep: true,
			immediate: true
		},
	},

	created() {
		this.checkUnseenMessages();
		this.updateRooms();
	},

	methods: {
		...mapActions({
            updateUsersList: "telegramBotMessangerUser/index",
            updateMessagesList: "telegramBotMessangerMessage/index",
            storeAndSendMessage: "telegramBotMessangerMessage/store",
            setPagination: "telegramBotMessangerMessage/setPagination",
            updateSort: "telegramBotMessangerMessage/updateSort",
            updateFilter: "telegramBotMessangerMessage/updateFilter",
            updateColumn: "telegramBotMessangerMessage/updateColumn",
            updatePagination: "telegramBotMessangerMessage/updatePagination",
            show: "telegramBotMessangerMessage/show",
            empty: "telegramBotMessangerMessage/empty",
            delete: "telegramBotMessangerMessage/destroy",
            refreshData: "telegramBotMessangerMessage/refreshData",
            getUnseenMessages: "telegramBotMessangerMessage/getUnseenMessages",
        }),
		openFile({ file }) {
			window.open(file.file.url, '_blank')
		},

		updateRooms(){
			this.updateUsersList({})
				.then(res => {
					let new_rooms = JSON.parse(JSON.stringify(this.userList));
					this.rooms = [...new_rooms];
					this.roomsLoaded = true;
				})
				.catch(err => {
					
				});
		},

		checkUnseenMessages() {
            this.messageWatchInterval = setInterval(() => {
				if(this.roomParams.id && this.roomParams.chat_id){
					this.updateRooms();
					this.getUnseenMessages(this.roomParams)
						.then(res => {
							if (this.unseenMessages.length > 0) {
								this.messages = [...this.messages, ...this.unseenMessages];
								let lastElement = this.unseenMessages.slice(-1);
								this.roomParams.id = lastElement[0] ? lastElement[0].id : '';
								this.roomParams.chat_id = this.roomParams.chat_id
								this.roomParams.id = lastElement[0] ? lastElement[0].id : ''
							}
						})
						.catch(err => {
							console.log(err, 'err unseenMessages');
						});
				}
                
            }, 6000); // every 10 seconds
        },

		fetchMoreRooms({ options = {} }) {
			this.updateUsersList({})
				.then(res => {
					this.rooms = JSON.parse(JSON.stringify(this.userList));
					this.rooms = [...this.rooms];
				})
				.catch(err => {
					
				});
		},
		fetchMessages({ room, options = {} }) {
			if(room && room.roomId){
				if(options.reset){
					this.messages = [];
					this.current_page = 1;
				}else{
					++this.current_page; 
				}
				// this.resetRoomMessageCount(room.roomId);
				let query = {chat_id: room.roomId, per_page: 30, page: this.current_page};
				this.updateMessagesList(query)
					.then(res => {
						let messages = JSON.parse(JSON.stringify(this.messageList));
						setTimeout(() => {
							this.addMessages(messages, room.roomId, options.reset);
							// this.messages = [...this.messages];
							if (this.pagination && this.messages.length >= this.pagination.total) {
								this.messagesLoaded = true;
							}
						}, 200)
					})
					.catch(err => {
						
					})
			}

		},

		resetRoomMessageCount(roomId){
			if(this.rooms.length > 0 && roomId){
				let room_index = this.rooms.findIndex(el => el.roomId == roomId);
				if(this.rooms[room_index]){
					this.rooms[room_index].unreadCount = 0;
					this.rooms = [...this.rooms]
				}
			}
		},

		addMessages(messages, roomId, reverse = false) {
			if(reverse){
				this.messages = [...this.messages, ...messages];
			}else{
				this.messages = [...messages, ...this.messages];
			}
			let lastElement = this.messages.slice(-1);
			this.updateRoomParams(lastElement[0], roomId);
		},

		sendMessage(message) {
			let content ='';
			if(message.files && message.files[0]){
				switch (true) {
					case message.files[0].type.includes('audio'):
						content = this.$t('message.voice');
					case message.files[0].type.includes('image'):
						content = this.$t('message.image');
					case message.files[0].type.includes('video'):
						content = this.$t('message.video');
					default:
						content = this.$t('message.document');
				}
			}
			let new_message = {
				content: message.content ? message.content : content,
				chat_id: message.roomId,
			}
			

			let formData = new FormData();
			for (const key in new_message) {
				if (new_message[key]) {
					formData.append(key, new_message[key]);
				} else {
					formData.append(key, '');
				}
          	}
			if (message.files && message.files[0] && _.size(message.files[0]) > 0) {
				let file = message.files[0];
				for (const key in file) {
					if (file.hasOwnProperty(key)) {
						const element = file[key];
						formData.append(`file[${key}]`, element);
					}
				}
          	}
			
			this.storeAndSendMessage(formData)
				.then(res => {
					let new_message = res.data.result.data.telegramBotMessangerMessage;
					if(new_message){
						this.messages = [
							...this.messages,
							new_message
						];
						this.updateRoomParams(new_message, message.roomId);
					}
				})
				.catch(err => {
					console.log(err, 'errerrerr');
				})
		},

		updateRoomParams(last_message, chat_id){
			this.roomParams.id = last_message ? last_message.id : '';
			this.roomParams.chat_id = chat_id
		},
		
	},
	beforeRouteLeave(to, from, next) {
		// Clean up the interval before leaving the route
		this.roomParams.id = '';
		this.roomParams.chat_id = '';
		setTimeout(() => {
			next();
		}, 50);
	},
}
</script>

<style lang="scss">
body {
	font-family: 'Quicksand', sans-serif;
}
</style>